<template>
<div>
    <NavBar />
  <div class="d-md-flex h-md-100" style="height:100vh;">

        <!-- First Half -->
        <div class="col-md-6 p-0 bg-indigo" >
            <div class="text-white p-5 text-center justify-content-center align-content-center">
                <div class="logoarea pt-5 pb-5" style="position: fixed; margin: 0 auto; top: 20%; left: 15%;">
                    <div v-if="isLoaded === true && isLoading === true" class="col md-4">
                        <label for="formFile">Ett artikelregister har hittats!</label><br>
                        <button type="button" class="btn btn-primary" @click="onFileRemove" >Ta bort</button>
                    </div>
                    <div v-else class="col md-4">
                        <input class="form-control" type="file" id="formFile" @change="onFileChange">
                    </div>
                </div>
            </div>
        </div>

        <!-- Second Half -->
        <div class="col-md-6 p-0 h-md-50 loginarea" style="height: 100%;" >
            <div class="bg-white h-md-100 p-5 justify-content-center" style="height: 200vh;">
                <div class="align-items-center">
                    <h1>För att artikeldatabasen ska fungera måste du följa dessa steg:</h1>
                    <br>
                    <h3>Använd gärna .xlsx</h3>
                    <br><br>
                    <p>Så här borde ditt dokument se ut:</p>
                </div>
                <div class="align-items-center h-md-100 p-5">
                    <h1>Steg 1</h1>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";

import { uploadArticleData, existsArticleData, removeArticleData} from "@/firebase";

export default {
  name: "ArticleDatabase",
  components: {
    NavBar,
  },
  setup() {

    const articleUploaded = existsArticleData();


    return { removeArticleData, articleUploaded}
  },
  data() {
    return {
      isLoaded: true,
    }
  },
  methods: {
    onFileChange(event) {
      let xlsxfile = event.target.files ? event.target.files[0] : null;
      console.log(xlsxfile)
      uploadArticleData(xlsxfile);
      this.isLoading = true;
    },
    onFileRemove() {
      this.removeArticleData();
      this.isLoaded = false;
    },
  },
  computed: {
    isLoading: {
      get() {
        console.log(this.articleUploaded)
        return (this.articleUploaded === 1) ? true : false;
      },
      set(isLoading) {
        this.isLoaded = isLoading
      }
    }
  }
}
</script>
